export function queryParamToString(queryparam: any): null | string {
  if (!queryparam) {
    return null;
  }

  if (Array.isArray(queryparam)) {
    return '' + queryparam[0];
  } else {
    return '' + queryparam;
  }
}
