import Select from '~/components/select';
import { DealerObjectType, useDealersQuery } from '~/generated/graphql';
import React from 'react';

export type DealerSelectOption = {
  value: string;
  label: string;
  data: DealerObjectType;
};

export interface DealerSelectProps {
  value?: string;
  onSelect: (value?: DealerSelectOption) => any;
  isDisabled?: boolean;
  isRequired?: boolean;
  error?: any;
}

export interface IMinimalDealer {
  _id: string;
  id: number;
  companyName: string;
  vatNumber: string;
  email: string;
  phonenumber: string;
}

export const optionFromDealerObject = (dealer: IMinimalDealer) => {
  return {
    value: dealer._id,
    label: dealer.companyName,
    data: dealer,
  };
};

export function DealerSelect(props: DealerSelectProps) {
  const { onSelect, value, isDisabled, isRequired, error } = props;
  const [queryResults] = useDealersQuery({
    variables: {
      pagination: {
        skip: 0,
        limit: 50,
      },
    },
    requestPolicy: 'cache-and-network',
  });

  const options = React.useMemo(() => {
    return (
      queryResults.data?.dealers?.docs?.map((dealer) => {
        return optionFromDealerObject(dealer);
      }) || []
    );
  }, [queryResults]);

  const selectedOption = React.useMemo(() => {
    return options.find((o) => o.value === value);
  }, [value, options]);

  return (
    <Select
      placeholder="Select dealer..."
      options={options}
      onSelect={(newValue) => {
        if (newValue.length) {
          // @ts-ignore
          onSelect(newValue[0]);
        } else {
          onSelect(null);
        }
      }}
      value={selectedOption ? [selectedOption] : []}
      isLoading={queryResults.fetching}
      isDisabled={isDisabled}
      required={isRequired}
      error={error}
      testId="dealer-select"
    />
  );
}
