import useClickOutside from '~/hooks/use-click-outside';
import useDebouncedFlag from '~/hooks/use-debounced-flag';
import useRefs from '~/hooks/use-refs';
import ChevronDownIcon from '~/icons/chevron-down.svg';
import ChevronUpIcon from '~/icons/chevron-up.svg';
import classNames from 'classnames';
import React, { useCallback } from 'react';

import { CheckMark } from './checkbox';
import Spinner from './spinner';
import Tag from './tag';

export interface OptionType {
  value: any;
  label: string;
  appearance?: 'default' | 'success' | 'info' | 'warning' | 'error' | string;
  [key: string]: any;
}

export interface Props {
  options: Array<OptionType>;
  value: Array<OptionType>;
  onSelect: (option: Array<OptionType>) => any;
  isLoading?: boolean;
  onSearch?: (query: string) => any;
  isMultiSelect?: boolean;
  error?: boolean | string;
  isDisabled?: boolean;
  placeholder: string;
  required?: boolean;
  testId?: string;
}

export const Select: React.FC<Props> = (props) => {
  let {
    options,
    value,
    onSelect,
    isLoading,
    onSearch,
    isMultiSelect,
    error,
    isDisabled,
    placeholder,
    required,
    testId,
  } = props;
  let [labelValue, setLabelValue] = React.useState('');
  let optionRefs = useRefs(options.length);
  let inputRef = React.createRef<HTMLInputElement>();
  let [isOpen, switchIsOpen, setIsOpen] = useDebouncedFlag(false);
  let clickOutsideCallback = useCallback(() => setIsOpen(false), [setIsOpen]);
  let containerRef = useClickOutside(clickOutsideCallback);

  React.useEffect(() => {
    if (value.length && !isMultiSelect) {
      setLabelValue(value[0]?.label || '');
    }
  }, [value?.length && value[0]?.label, isMultiSelect]);

  const handleContainerKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (isDisabled) {
      return;
    }

    if (e.key === 'Enter' || (!onSearch && e.key === ' ')) {
      e.preventDefault();
      e.stopPropagation();

      switchIsOpen();
    }

    if (e.key === 'ArrowDown') {
      e.preventDefault();
      e.stopPropagation();

      let firstOptionRef = optionRefs[0];
      if (firstOptionRef && firstOptionRef.current) {
        firstOptionRef.current.focus();
      }
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isDisabled) {
      return;
    }

    let query = e.target.value;
    if (onSearch) {
      onSearch(query);
      setLabelValue(query);
      setIsOpen(true);
    }
  };

  let label: React.ReactNode = '';
  if (value.length < 1 && !onSearch) {
    label = <span className="text-gray-400 px-2 w-full">{placeholder}</span>;
  } else if (value.length > 0) {
    if (isMultiSelect) {
      label = (
        <div
          className={classNames('whitespace-nowrap', {
            'w-full': !onSearch,
          })}
        >
          {value.map((option, i) => (
            <Tag appearance={option.appearance} key={`multiselect-tag=${i}`}>
              {option.label}
            </Tag>
          ))}
        </div>
      );
    } else if (!onSearch) {
      let option = value[0];

      label = (
        <span
          className={classNames('whitespace-nowrap px-2 text-gray-800', {
            'w-full': !onSearch,
          })}
        >
          {option?.label || ''}
        </span>
      );
    }
  }

  return (
    <div className="relative w-full" ref={containerRef}>
      <div
        className={classNames('rounded border shadow h-8 flex items-center justify-start overflow-hidden bg-white', {
          'cursor-default bg-gray-200': isDisabled,
          'cursor-pointer hover:border-gray-400 text-gray-900': !isDisabled,
          'text-red-500 border-red-400 hover:border-red-600': !!error,
        })}
        tabIndex={isDisabled ? undefined : 0}
        onClick={(e) => {
          if (isDisabled) {
            return;
          }

          e.preventDefault();
          e.stopPropagation();

          if (!onSearch || !isOpen) {
            switchIsOpen();
          }
        }}
        onKeyDown={handleContainerKeyDown}
        role="presentation"
        test-id={testId}
      >
        {label}
        {!!onSearch && (
          <input
            type="text"
            className="bg-transparent px-2 w-full outline-none"
            value={labelValue}
            ref={inputRef}
            tabIndex={-1}
            placeholder={placeholder}
            onChange={handleSearch}
          ></input>
        )}
        <div
          className="h-full px-2 flex items-center text-gray-600"
          onClick={(e) => {
            if (isDisabled) {
              return;
            }

            e.preventDefault();
            e.stopPropagation();

            switchIsOpen();
          }}
        >
          {isLoading ? (
            <Spinner size={4} />
          ) : isOpen ? (
            <ChevronUpIcon className="w-6 h-6" />
          ) : (
            <ChevronDownIcon className="w-6 h-6" />
          )}
        </div>
      </div>

      <div
        className={classNames('bg-white border shadow w-full overflow-x-auto ', {
          absolute: isOpen,
          hidden: !isOpen,
        })}
        style={{
          zIndex: 1000,
          maxHeight: 200,
        }}
      >
        {options.map((option, i) => {
          let ref = optionRefs[i];
          let selectedIndex = value.findIndex((v) => v.value === option.value);
          let isSelected = selectedIndex > -1;

          const handleSelectOption = () => {
            if (isDisabled) {
              return;
            }

            let newValue: Array<OptionType> = [];
            if (isMultiSelect) {
              if (!isSelected) {
                newValue = [...value, option];
              } else {
                newValue = [...value];
                newValue.splice(selectedIndex, 1);
              }
            } else {
              if (!isSelected || required) {
                newValue = [option];
              } else {
                newValue = [];
              }

              setIsOpen(false);
            }

            onSelect(newValue);

            if (!newValue.length || isMultiSelect) {
              setLabelValue('');
            }
          };

          const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
            if (isDisabled) {
              return;
            }

            let isHandled = false;
            if (e.key === 'Enter' || (!onSearch && e.key === ' ')) {
              handleSelectOption();
              isHandled = true;
            }

            if (e.key === 'ArrowDown' && i < options.length - 1) {
              let nextRef = optionRefs[i + 1];
              if (nextRef && nextRef.current) {
                nextRef.current.focus();
                isHandled = true;
              }
            }

            if (e.key === 'ArrowUp' && i > 0) {
              let prevRef = optionRefs[i - 1];
              if (prevRef && prevRef.current) {
                prevRef.current.focus();
                isHandled = true;
              }
            }

            if (isHandled) {
              e.preventDefault();
              e.stopPropagation();
            }
          };

          let itemTestId = testId ? `${testId}-item-${option.value}` : undefined;

          return (
            <div
              onClick={handleSelectOption}
              onKeyDown={handleKeyDown}
              tabIndex={0}
              className="flex items-center px-2 py-1 w-full cursor-pointer"
              ref={ref}
              key={`option-${i}`}
              role="menuitem"
              test-id={itemTestId}
            >
              {isMultiSelect && <CheckMark checked={isSelected}></CheckMark>}
              <span
                className={classNames('ml-2 whitespace-nowrap', {
                  'text-gray-800': option?.appearance === 'default',
                  'text-green-600': option?.appearance === 'success',
                  'text-blue-600': option?.appearance === 'info',
                  'text-yellow-600': option?.appearance === 'warning',
                  'text-red-500': option?.appearance === 'danger',
                })}
              >
                {option.label}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Select;
