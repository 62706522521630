import { useTranslator } from '@translator-app/react';
import { Button } from '~/components/button';
import React from 'react';

interface PaginationData {
  skip: number;
  limit: number;
}

export type Props = {
  totalItems: number;
  skip: number;
  limit: number;
  onPageSelect: (data: PaginationData) => any;
  isLoading?: boolean;
};

export const Dots = () => <div className="mx-2">...</div>;

export default function Pagination(props: Props) {
  let { totalItems, skip, limit, onPageSelect, isLoading = false } = props;
  let { translate } = useTranslator();

  const selectedPage = Math.floor(skip / limit) + 1;
  const lastPage = Math.ceil(totalItems / limit);

  let startPage = selectedPage > 1 ? selectedPage - 1 : selectedPage;
  if (startPage < 1) {
    startPage = 1;
  }

  let startDots = startPage > 2;
  let endDots = lastPage > startPage + 3;
  let amountOfPages = 5;
  if (endDots || startDots) {
    if ((endDots && startDots) || endDots) {
      amountOfPages = 3;
    } else {
      amountOfPages = lastPage - startPage + 1;
    }
  }

  if (lastPage <= 5) {
    startPage = 1;
    amountOfPages = lastPage;
    startDots = false;
    endDots = false;
  }

  const handlePageSelect = (newPage: number) => {
    onPageSelect({
      skip: (newPage - 1) * limit,
      limit,
    });
  };

  return (
    <div className="flex justify-between items-center py-2">
      <div className="mx-2">
        {skip}-{Math.min(totalItems, selectedPage * limit)} of {totalItems} items
      </div>

      <div className="flex items-center">
        <div className="mr-2">
          <Button
            appearance="subtle"
            onPress={() => handlePageSelect(selectedPage - 1)}
            isDisabled={selectedPage <= 1 || isLoading}
          >
            {translate('previous')}
          </Button>
        </div>
        {startDots && (
          <React.Fragment>
            <Button appearance="subtle" onPress={() => handlePageSelect(1)} isDisabled={isLoading}>
              1
            </Button>
            <div className="mr-2"></div>
            <Dots />
          </React.Fragment>
        )}
        {new Array(amountOfPages).fill('').map((_, i) => {
          let pageNumber = startPage + i;

          return (
            <Button
              key={`page-${pageNumber}`}
              appearance="subtle"
              onPress={() => handlePageSelect(pageNumber)}
              isDisabled={isLoading}
              isLoading={isLoading && pageNumber === selectedPage}
              isSelected={pageNumber === selectedPage}
            >
              {pageNumber}
            </Button>
          );
        })}
        {endDots && (
          <React.Fragment>
            <Dots />
            <Button appearance="subtle" onPress={() => handlePageSelect(lastPage)} isDisabled={isLoading}>
              {lastPage}
            </Button>
          </React.Fragment>
        )}
        <div className="ml-2">
          <Button
            appearance="subtle"
            onPress={() => handlePageSelect(selectedPage + 1)}
            isDisabled={selectedPage >= lastPage || isLoading}
          >
            {translate('next')}
          </Button>
        </div>
      </div>
    </div>
  );
}
