import { useRouter } from 'next/router';
import { useMemo, useState } from 'react';

import { queryParamToString } from '../utils/query';

export function usePaginationQuery(defaultPageSize: number = 20) {
  const router = useRouter();
  const query = router.query;
  return useMemo(() => {
    const skip = query.skip ? parseInt(queryParamToString(query.skip), 10) : 0;
    const limit = query.limit ? parseInt(queryParamToString(query.limit), 10) : defaultPageSize;

    return { skip, limit };
  }, [query?.skip, query?.limit]);
}

export function usePaginationState(limit: number = 20) {
  const [skip, setSkip] = useState(0);
  return { skip, setSkip, limit };
}
