import classNames from 'classnames';
import React from 'react';

export interface TextFieldProps {
  id?: string;
  value: any;
  placeholder: string;
  onChange: (value: string) => any;
  name?: string;
  error?: string | boolean;
  type?: string;
  disabled?: boolean;
  onFocus?: (evt: React.FocusEvent<HTMLInputElement>) => any;
  onBlur?: (evt: React.FocusEvent<HTMLInputElement>) => any;
  onSubmit?: () => any;
  iconStart?: React.ReactNode;
  iconEnd?: React.ReactNode;
}

export default function TextField(props: TextFieldProps) {
  let {
    id,
    value = '',
    onChange,
    type,
    placeholder,
    onFocus,
    onBlur,
    onSubmit,
    disabled,
    error,
    iconStart,
    iconEnd,
    name,
  } = props;
  let ref = React.useRef<any>();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e.target.value);
    }
  };

  const handleBlur = (evt: React.FocusEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur(evt);
    }
  };

  const handleFocus = (evt: React.FocusEvent<HTMLInputElement>) => {
    if (onFocus) {
      onFocus(evt);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (ref && ref.current) {
        ref.current.blur();
      }

      if (onSubmit) {
        onSubmit();
      }
    }
  };

  let styling = classNames('rounded border shadow h-8 flex items-center justify-start overflow-hidden bg-white', {
    'cursor-default bg-gray-200': disabled,
    'cursor-text hover:border-gray-400': !disabled,
    'text-red-500 border-red-400 hover:border-red-600': !!error,
  });

  return (
    <div
      className={styling}
      onFocus={() => {
        if (ref && ref.current) {
          ref.current.focus();
        }
      }}
      tabIndex={!disabled ? 0 : undefined}
      role="textbox"
    >
      {iconStart && <div>{iconStart}</div>}
      <input
        className={classNames('w-full bg-transparent h-full px-2 focus:bg-white text-gray-800 rounded', {
          'cursor-text': !disabled,
        })}
        id={id}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        type={type}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        ref={ref}
        name={name}
        disabled={disabled}
        tabIndex={-1}
        autoComplete="off"
      ></input>
      {iconEnd && <div>{iconEnd}</div>}
    </div>
  );
}
