import classNames from 'classnames';
import React from 'react';

export type Props = {
  children: React.ReactNode | Array<React.ReactNode>;
  appearance?: 'heading' | 'subheading';
  tag?: 'h1' | 'h2' | 'h3' | 'h4';
};

export default function Heading(props: Props) {
  let { children, appearance = 'main', tag = 'h1' } = props;

  return (
    <div
      className={classNames({
        'mb-8': appearance === 'main',
        'mb-4': appearance === 'subheading',
      })}
    >
      {React.createElement(
        tag,
        {
          className: classNames('text-gray-800', {
            'text-xl font-bold': appearance === 'main',
            'text-lg font-medium': appearance === 'subheading',
          }),
        },
        children
      )}
      {appearance === 'main' && <div className="h-1 w-16 bg-blue-500" />}
    </div>
  );
}
