import CheckIcon from '~/icons/check.svg';
import classNames from 'classnames';
import React from 'react';

export type CheckMarkAppearance = 'default' | 'warning' | 'danger';

export interface CheckBoxProps {
  hideLabel?: boolean;
  error?: string | boolean;
  appearance?: CheckMarkAppearance;
  isSelected?: boolean;
  isDisabled?: boolean;
  onChange?: (isSelected: boolean) => void;
  testId?: string;
}

export interface CheckMarkProps {
  checked?: boolean;
  isDisabled?: boolean;
  appearance?: CheckMarkAppearance;
}

export const CheckMark: React.FC<CheckMarkProps> = (props) => {
  let { checked, isDisabled, appearance = 'default' } = props;

  return (
    <div
      className={classNames('flex justify-center items-center border rounded shadow cursor-pointer w-4 h-4', {
        'bg-blue-400': checked && !isDisabled && appearance === 'default',
        'bg-yellow-400': checked && !isDisabled && appearance === 'warning',
        'bg-red-400': checked && !isDisabled && appearance === 'danger',
        'bg-gray-400': checked && isDisabled,
        'bg-gray-200': !checked && isDisabled,
        'bg-white': !checked && !isDisabled,
      })}
      aria-hidden="true"
    >
      {checked && <CheckIcon className={'text-white w-2 h-2'} />}
    </div>
  );
};

export const CheckBox: React.FC<CheckBoxProps> = (props) => {
  let { children, hideLabel, onChange, isDisabled, error, appearance = 'default', isSelected, testId } = props;

  return (
    <div
      className={classNames('flex justify-start items-center text-gray-800 cursor-pointer rounded', {
        'h-8': !hideLabel,
        'text-red-400': !!error,
      })}
      tabIndex={isDisabled ? -1 : 1}
      onClick={(evt) => {
        evt.preventDefault();
        evt.stopPropagation();

        if (!isDisabled) {
          onChange(!isSelected);
        }
      }}
      test-id={testId}
    >
      <CheckMark checked={isSelected} isDisabled={isDisabled} appearance={appearance} />
      <span
        className={classNames('ml-2', {
          'visually-hidden': hideLabel,
        })}
      >
        {children}
      </span>
    </div>
  );
};
