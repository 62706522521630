import Avatar from '~/components/avatar';
import Card from '~/components/card';
import React from 'react';

interface Props {
  title: string;
  onPress: () => void;
  buttons?: React.ReactNode | Array<React.ReactNode>;
  testId?: string;
}

export const SelectionCard: React.FC<Props> = (props) => {
  let { title, children, onPress, buttons, testId } = props;

  return (
    <Card>
      <div className="p-2 flex cursor-pointer" onClick={onPress} test-id={testId}>
        <div>
          <Avatar size={10} className="bg-green-500">
            {title[0]}
          </Avatar>
          <div className="flex flex-col items-start justify-center my-2">{buttons}</div>
        </div>
        <div className="mx-4 w-full">
          <div className="uppercase font-medium text-gray-800 text-lg border-b w-full mb-2">{title}</div>
          <div>{children}</div>
        </div>
      </div>
    </Card>
  );
};
