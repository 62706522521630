import TimesIcon from '~/icons/x.svg';
import classNames from 'classnames';
import React from 'react';

export type Props = {
  children: React.ReactNode | React.ReactNodeArray;
  appearance?: 'default' | 'success' | 'info' | 'warning' | 'danger' | string;
  isRemovable?: boolean;
  onRemove?: () => {};
};

export default function Tag(props: Props) {
  let { children, isRemovable, onRemove, appearance = 'default' } = props;

  const handleKeyDown = (e: React.KeyboardEvent<SVGSVGElement>) => {
    if (onRemove && e.key === 'Enter') {
      onRemove();
    }
  };

  const handleClick = () => onRemove && onRemove();

  return (
    <div
      className={classNames('whitespace-nowrap inline-flex px-2 text-sm rounded font-medium items-center mx-1', {
        'bg-gray-200 text-gray-900': appearance === 'default',
        'bg-green-500 text-white': appearance === 'success',
        'bg-blue-400 text-white': appearance === 'info',
        'bg-yellow-300 text-gray-900': appearance === 'warning',
        'bg-red-500 text-white': appearance === 'danger',
      })}
    >
      <div>{children}</div>
      {isRemovable && (
        <TimesIcon
          className={classNames('text-white cursor-pointer ml-2 w-3 h-3 rounded-full', {
            'bg-gray-800': appearance === 'default',
            'bg-green-800': appearance === 'success',
            'bg-blue-800': appearance === 'info',
            'bg-yellow-800': appearance === 'warning',
            'bg-red-800': appearance === 'danger',
          })}
          onClick={handleClick}
          onKeyDown={handleKeyDown}
          tabIndex={0}
          role="button"
        />
      )}
    </div>
  );
}
