import React from 'react';

export default function useRefs<T = React.RefObject<any>>(amount: number) {
  let [refs, setRefs] = React.useState<Array<T>>([]);

  React.useEffect(() => {
    if (refs.length > amount) {
      setRefs(refs.slice(0, refs.length - 1));
    } else {
      let optionRefsClone = [...refs];
      let newArr = new Array(amount - refs.length).fill('').map(() => React.createRef());

      // @ts-ignore
      optionRefsClone.push(...newArr);

      setRefs(optionRefsClone);
    }
  }, [amount]);

  return refs;
}
