import classNames from 'classnames';
import * as React from 'react';

export type Props = {
  children: React.ReactNode;
  className?: string;
};

export default function Card(props: Props) {
  let { children, className } = props;

  let styling = classNames('rounded-md shadow-md border border-gray-200 bg-white', className);

  return <div className={styling}>{children}</div>;
}
