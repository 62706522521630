import { useUserContext } from '~/contexts/user-context';
import React from 'react';

export type Props = {
  role: number;
  children: React.ReactNode | React.ReactNodeArray;
};

const HideUnauthorized: React.FC<Props> = (props) => {
  let { role, children } = props;
  let { highestRole } = useUserContext();

  return <React.Fragment>{role <= highestRole ? children : null}</React.Fragment>;
};

export default HideUnauthorized;
