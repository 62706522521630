import { useTranslator } from '@translator-app/react';
import { DealerSelect } from '~/app/dealer/dealer-select';
import { ErrorFallback } from '~/app/error/error';
import { Button } from '~/components/button';
import Heading from '~/components/heading';
import HideUnauthorized from '~/components/hide-unauthorized';
import PageSpinner from '~/components/page-spinner';
import Pagination from '~/components/pagination';
import { SelectionCard } from '~/components/selection-card';
import TextField from '~/components/textfield';
import { useRouter } from 'next/router';
import React from 'react';
import { useQuery } from 'urql';

import { AuthRolesEnum } from '../enums';
import { OrganisationObjectType, OrganisationPaginatedResultObjectType } from '../generated/graphql';
import { usePaginationQuery } from '../hooks/use-pagination';
import { PageWrapper } from '../templates/page';
import { queryParamToString } from '../utils/query';

const OrganisationFragment = `
fragment organisationFields on OrganisationObjectType {
  _id
  id
  name
  companyName
  vatNumber
  address {
    id
    street
    streetNumber
    city
    zipcode
    country
  }
}
`;

const OrganisationsQuery = `
  ${OrganisationFragment}

  query OrganisationsQuery($pagination: PaginationInputObjectType!, $dealerId: ObjectId) {
    organisations(pagination: $pagination, dealerId: $dealerId) {
      count
      total
      docs {
        ...organisationFields
      }
    }
  }
`;

const SearchOrganisationQuery = `
  ${OrganisationFragment}

  query SearchOrganisations($query: String!) {
    findOrganisations(query: $query) {
      ...organisationFields
    }
  }
`;

interface OrganisationsGridProps {
  organisations: Array<OrganisationObjectType>;
  isLoading: boolean;
  error: any;
}

const OrganisationsGrid: React.FC<OrganisationsGridProps> = (props) => {
  const router = useRouter();
  const { isLoading, error, organisations } = props;

  if (isLoading) {
    return <PageSpinner />;
  }

  if (error) {
    return <ErrorFallback />;
  }

  return (
    <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 py-4">
      {organisations.map((organisation) => {
        return (
          <SelectionCard
            key={organisation._id}
            title={organisation.name}
            onPress={() => {
              router.push('/[organisationId]', `/${organisation._id}`);
            }}
            testId={`organisation-card-${organisation._id}`}
          >
            <div className="font-medium text-sm flex">{organisation.vatNumber}</div>
            <div className="text-gray-600 text-sm">
              <div>{organisation.companyName}</div>
              <div>
                {organisation.address?.street} {organisation.address?.streetNumber}
              </div>
              <div>
                {organisation.address?.zipcode} {organisation.address?.city}
              </div>
              <div>{organisation.address?.country}</div>
            </div>
          </SelectionCard>
        );
      })}
    </div>
  );
};

const Page: React.FC = (props) => {
  let router = useRouter();
  let { query } = router;
  let { translate } = useTranslator();
  let pagination = usePaginationQuery();
  let dealerId = queryParamToString(query.dealerId);
  let [listOrganisationsResult] = useQuery<{ organisations: OrganisationPaginatedResultObjectType }>({
    query: OrganisationsQuery,
    variables: {
      pagination,
      dealerId,
    },
  });
  let [searchQuery, setSearchQuery] = React.useState('');
  let [searchResults] = useQuery<{ findOrganisations: Array<OrganisationObjectType> }>({
    query: SearchOrganisationQuery,
    variables: {
      query: searchQuery,
    },
  });

  const { organisations, isLoading, error } = React.useMemo(() => {
    if (searchQuery) {
      const results = searchResults.data?.findOrganisations || [];
      return {
        organisations: results,
        isLoading: !!(searchResults.fetching && results.length),
        error: searchResults.error,
      };
    } else {
      const results = listOrganisationsResult.data?.organisations?.docs || [];
      return {
        organisations: results,
        isLoading: !!(listOrganisationsResult.fetching && results.length),
        error: listOrganisationsResult.error,
      };
    }
  }, [searchResults, listOrganisationsResult, searchQuery]);

  const totalOrganisations = listOrganisationsResult.data?.organisations.total || 0;
  return (
    <div className="p-4 max-w-screen-xl mx-auto">
      <div className="flex flex-wrap justify-between">
        <Heading>{translate('organisationScreen.selectionHeading')}</Heading>
        <div className="flex">
          <div className="mr-4">
            <Button href="/announcements">Announcements</Button>
          </div>
          <HideUnauthorized role={AuthRolesEnum.Dealer}>
            <div className="flex">
              <div className="mr-4">
                <Button href="/admin">Admin Panel</Button>
              </div>
              <div>
                <Button appearance="primary" href="/admin/organisations/create">
                  {translate('organisationScreen.create')}
                </Button>
              </div>
            </div>
          </HideUnauthorized>
        </div>
      </div>

      <div className="flex flex-wrap">
        <div className="flex-grow">
          <TextField
            placeholder={translate('organisationScreen.search')}
            onChange={setSearchQuery}
            value={searchQuery}
          />
        </div>
        <div className="ml-4">
          <HideUnauthorized role={AuthRolesEnum.Dealer}>
            <DealerSelect
              value={dealerId}
              onSelect={(dealer) => {
                let queryClone = { ...query };
                if (!dealer) {
                  delete queryClone.dealerId;
                } else {
                  queryClone.dealerId = dealer.value;
                }
                router.push({ pathname: '/', query: queryClone });
              }}
            />
          </HideUnauthorized>
        </div>
      </div>

      <OrganisationsGrid organisations={organisations} isLoading={isLoading} error={error} />

      {!searchQuery && (
        <Pagination
          skip={pagination.skip}
          limit={pagination.limit}
          totalItems={totalOrganisations}
          onPageSelect={({ skip, limit }) => {
            router.push({ pathname: '/', query: { ...query, skip, limit } });
          }}
        />
      )}
    </div>
  );
};

function Index() {
  return (
    <PageWrapper>
      <Page />
    </PageWrapper>
  );
}

export default Index;
